<template>
  <section id="finance-report">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <h5>Years</h5>
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr v-model="dateRangesY[1].dateRange" placeholder="Date Range" class="form-control"
                :config="{ mode: 'range' }" @on-change="changeDateRangeY" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col xl="6" md="6">
          <h5>Months</h5>
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr v-model="dateRangesM[1].dateRange" placeholder="Date Range" class="form-control"
                :config="{ mode: 'range' }" @on-change="changeDateRangeM" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Finance report
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-finance-report" />
          </h4>
          <b-popover target="popover-finance-report" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-button-group>
              <b-overlay :show="showExportFullTableStart" spinner-variant="primary" variant="transparent" blur="3px"
                rounded="sm">
                <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                  @click="exportFullTableToExcel" />
              </b-overlay>
            </b-button-group>
          </b-card-text>
        </b-card-header>
        <div class="table-wrapper">
          <table class="table" ref="fullTable">
            <thead>
              <tr>
                <th class="sticky-col"><b class="text-secondary" style="font-size: 18px;">Bezeichnung</b></th>
                <th class="sticky-col"></th>
                <th v-for="(item, index) in results" :key="index">
                  <b class="text-secondary" style="font-size: 18px;">{{ item.month }}<span v-if="includesToday(item)">
                      YTD</span></b><br>
                  <b class="text-secondary" style="font-size: 10px;">{{ item.dateRange | formatDateRange }}</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Umsatzerlöse <feather-icon icon="InfoIcon" id="popover-umsatzerlose" /></b>
                  <b-popover target="popover-umsatzerlose" triggers="hover" placement="right">
                    <span>
                      <b>Waren + eingenommen Frachtkosten</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item['turnoverByTaxType']">
                    <b class="text-success">
                      {{ item['turnover'][0].turnover | formatCurrencyNumber }}
                    </b>
                    <feather-icon icon="DownloadIcon" @click="exportTurnoverPositions(item.dateRange)" />
                    <feather-icon :icon="expandedItems[index] ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                      @click="toggleTurnoverByTaxTypeDetail(index)" />
                  </div>
                  <div v-else>
                    <b class="text-success">
                      {{ item['turnover'][0].turnover | formatCurrencyNumber }}
                    </b>
                    <feather-icon icon="DownloadIcon" @click="exportTurnoverPositions(item.dateRange)" />
                  </div>
                  <div v-if="expandedItems[index] && item['turnoverByTaxType']">
                    <hr>
                    <p v-for="(item2, index2) in item['turnoverByTaxType']" :key="index2" style="font-size: 11px;">
                      - {{ item2.tax_type }} <b>{{ item2.shipped_from }}</b>: <b class="text-success">
                        {{ item2.turnover | formatCurrencyNumber }}
                      </b>
                    </p>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm"
                      @click="exportInvoicesByTaxType(item.dateRange)">
                      Export
                    </b-button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Waren <feather-icon icon="InfoIcon" id="popover-waren" />
                  <b-popover target="popover-waren" triggers="hover" placement="right">
                    <span>
                      <b>VKNetto*Anzahl*(1-Rabatt/100)</b> for items where nType IN (0, 1, 4) from RechnungPosition
                      table.
                      StuecklisteRechnungPos, GutschriftPos and Waehrung are taken into account
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  {{ item['turnover'][0].turnover_goods | formatCurrencyNumber }}
                  <feather-icon icon="InfoIcon" @click="openTurnoverGoodsDetails(item.dateRange)" />
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  eingenommen Frachtkosten <feather-icon icon="InfoIcon" id="popover-eingenommen-rachtkosten" />
                  <b-popover target="popover-eingenommen-rachtkosten" triggers="hover" placement="right">
                    <span>
                      <b>VKNetto*Anzahl*(1-Rabatt/100)</b> for items where nType NOT IN (0, 1, 4) from RechnungPosition
                      table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  {{ item['turnover'][0].freight_charges | formatCurrencyNumber }}
                  <feather-icon icon="InfoIcon" @click="openFreightChargesInDetails(item.dateRange)" />
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Wareneinsatz <feather-icon icon="InfoIcon" id="popover-wareneinsatz" /></b>
                  <b-popover target="popover-wareneinsatz" triggers="hover" placement="right">
                    <span>
                      <b>Waren + Frachtkosten + Nicht gebuchte Lieferantenbestellungen +
                        Lagerbestandsveränderung</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ displayTotalExpenses(item['expenses'][0].expenses,
                    item['expenses'][0].open_suporders_amount,
                    item['stockValueDiff']) | formatCurrencyNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Waren <feather-icon icon="InfoIcon" id="popover-waren-expenses" />
                  <b-popover target="popover-waren-expenses" triggers="hover" placement="right">
                    <span>
                      <b>EKNetto*Menge</b> for items where nPosTyp = 1 OR (nPosTyp = 0 AND cArtNr != '')
                      from EingangsrechnungPos table.
                      LandISO and EingangsrechnungPosZusatzkosten are taken into account, so for LandISO = 'DE' =>
                      <b>EKNetto*fMenge*(1+MwSt/100)</b>
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  {{ item['expenses'][0].expenses_goods | formatCurrencyNumber }}
                  <feather-icon icon="DownloadIcon" @click="exportGoodsCosts(item.dateRange)" />
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Frachtkosten <feather-icon icon="InfoIcon" id="popover-frachtkosten" />
                  <b-popover target="popover-frachtkosten" triggers="hover" placement="right">
                    <span>
                      <b>EKNetto*Menge</b> for all trasport items from EingangsrechnungPos table.
                      LandISO and EingangsrechnungPosZusatzkosten are taken into account
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  {{ item['expenses'][0].freight_charges | formatCurrencyNumber }}
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Nicht gebuchte Lieferantenbestellungen <feather-icon icon="InfoIcon"
                    id="popover-nicht-gebuchte-ieferantenbestellungen" />
                  <b-popover target="popover-nicht-gebuchte-ieferantenbestellungen" triggers="hover" placement="right">
                    <span>
                      Open supplier orders is calculated: <b>EKNetto*Menge*(1+UST/100)</b> for items where Deleted = 0
                      AND Eingangsrechnung IS NULL AND
                      Status IN (500, 650, 700) from LieferantenBestellung table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  {{ item['expenses'][0].open_suporders_amount | formatCurrencyNumber }}
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Lagerbestandsveränderung <feather-icon icon="InfoIcon" id="popover-lagerbestandsveranderung" />
                  <b-popover target="popover-lagerbestandsveranderung" triggers="hover" placement="right">
                    <span>
                      Purchasing stock value begin of the period - Purchasing stock value end of the period
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  {{ item['stockValueDiff'] | formatCurrencyNumber }}
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Bruttomarge (Rohertrag) <feather-icon icon="InfoIcon"
                      id="popover-bruttomarge-rohertrag" /></b>
                  <b-popover target="popover-bruttomarge-rohertrag" triggers="hover" placement="right">
                    <span>
                      <b>Umsatzerlöse - Wareneinsatz</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b v-if="displayGrossMargin(item['turnover'][0].turnover, item['expenses'][0].expenses, item['expenses'][0].open_suporders_amount, item['stockValueDiff']) > 0"
                    class="text-success">
                    {{ displayGrossMargin(item['turnover'][0].turnover, item['expenses'][0].expenses,
                      item['expenses'][0].open_suporders_amount,
                      item['stockValueDiff']) | formatCurrencyNumber }}
                  </b>
                  <b v-else class="text-danger">
                    {{ displayGrossMargin(item['turnover'][0].turnover, item['expenses'][0].expenses,
                      item['expenses'][0].open_suporders_amount,
                      item['stockValueDiff']) | formatCurrencyNumber }}
                  </b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Bruttomarge % <feather-icon icon="InfoIcon" id="popover-bruttomarge" /></b>
                  <b-popover target="popover-bruttomarge" triggers="hover" placement="right">
                    <span>
                      <b>Bruttomarge / Umsatzerlöse * 100</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b v-if="displayGrossMarginP(displayGrossMargin(item['turnover'][0].turnover, item['expenses'][0].expenses, item['expenses'][0].open_suporders_amount, item['stockValueDiff']), item['turnover'][0].turnover) > 0"
                    class="text-success">
                    {{ displayGrossMarginP(displayGrossMargin(item['turnover'][0].turnover,
                      item['expenses'][0].expenses,
                      item['expenses'][0].open_suporders_amount, item['stockValueDiff']), item['turnover'][0].turnover) |
                      formatNumber }} %
                  </b>
                  <b v-else class="text-danger">
                    {{ displayGrossMarginP(displayGrossMargin(item['turnover'][0].turnover,
                      item['expenses'][0].expenses,
                      item['expenses'][0].open_suporders_amount, item['stockValueDiff']), item['turnover'][0].turnover) |
                      formatNumber }} %
                  </b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Personalkosten <feather-icon icon="InfoIcon"
                      id="popover-personalkosten" /></b>
                  <b-popover target="popover-personalkosten" triggers="hover" placement="right">
                    <span>
                      Sum of all Personalkosten categories
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ displayStaffCostTotal(item['staffCosts']['sums']) | formatCurrencyNumber
                    }}</b>&nbsp;
                  <feather-icon icon="DownloadIcon" @click="exportStaffCosts(item.dateRange)" />
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Lager <feather-icon icon="InfoIcon" id="popover-lager" />
                  <b-popover target="popover-lager" triggers="hover" placement="right">
                    <span>
                      This data is taken from Timemoto API for current month. For past months data is taken from
                      'Übersicht Personalkosten' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item['staffCosts']['sums']['Warehouse']">
                    {{ item["staffCosts"]["sums"]["Warehouse"] | formatCurrencyNumber }}
                    <feather-icon icon="InfoIcon" :id="'popover-warehouse' + index" />
                    <b-popover :target="'popover-warehouse' + index" triggers="hover" placement="left">
                      <div v-for="(i, j) in item['staffCosts']['groupedByEmployee']['Warehouse']" :key="j">
                        {{ j + 1 }}. {{ i.employee }} - {{ i.amount | formatCurrencyNumber }}
                      </div>
                    </b-popover>
                  </div>
                  <div v-else>
                    0,00 €
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Verwaltung <feather-icon icon="InfoIcon" id="popover-verwaltung" />
                  <b-popover target="popover-verwaltung" triggers="hover" placement="right">
                    <span>
                      This data is taken from Traqq report for current month. For past months data is taken from
                      'Übersicht Personalkosten' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item['staffCosts']['sums']['Administration']">
                    {{ item["staffCosts"]["sums"]["Administration"] | formatCurrencyNumber }}
                    <feather-icon icon="InfoIcon" :id="'popover-administration' + index" />
                    <b-popover :target="'popover-administration' + index" triggers="hover" placement="left">
                      <div v-for="(i, j) in item['staffCosts']['groupedByEmployee']['Administration']" :key="j">
                        {{ j + 1 }}. {{ i.employee }} - {{ i.amount | formatCurrencyNumber }}
                      </div>
                    </b-popover>
                  </div>
                  <div v-else>
                    0.0 €
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Einkauf (Dienstl Martinez) <feather-icon icon="InfoIcon" id="popover-einkauf" />
                  <b-popover target="popover-einkauf" triggers="hover" placement="right">
                    <span>
                      This data is taken from Traqq report for current month. For past months data is taken from
                      'Übersicht Personalkosten' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item['staffCosts']['sums']['Purchasing']">
                    {{ item["staffCosts"]["sums"]["Purchasing"] | formatCurrencyNumber }}
                    <feather-icon icon="InfoIcon" :id="'popover-purchasing' + index" />
                    <b-popover :target="'popover-purchasing' + index" triggers="hover" placement="left">
                      <div v-for="(i, j) in item['staffCosts']['groupedByEmployee']['Purchasing']" :key="j">
                        {{ j + 1 }}. {{ i.employee }} - {{ i.amount | formatCurrencyNumber }}
                      </div>
                    </b-popover>
                  </div>
                  <div v-else>
                    0,00 €
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Support (Personalkosten) <feather-icon icon="InfoIcon" id="popover-support" />
                  <b-popover target="popover-support" triggers="hover" placement="right">
                    <span>
                      This data is taken from Traqq report for current month. For past months data is taken from
                      'Übersicht Personalkosten' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item['staffCosts']['sums']['Support']">
                    {{ item["staffCosts"]["sums"]["Support"] | formatCurrencyNumber }}
                    <feather-icon icon="InfoIcon" :id="'popover-support' + index" />
                    <b-popover :target="'popover-support' + index" triggers="hover" placement="left">
                      <div v-for="(i, j) in item['staffCosts']['groupedByEmployee']['Support']" :key="j">
                        {{ j + 1 }}. {{ i.employee }} - {{ i.amount | formatCurrencyNumber }}
                      </div>
                    </b-popover>
                  </div>
                  <div v-else>
                    0,00 €
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  IT (Entwicklungskosten IT) <feather-icon icon="InfoIcon" id="popover-it" />
                  <b-popover target="popover-it" triggers="hover" placement="right">
                    <span>
                      This data is taken from Traqq report for current month. For past months data is taken from
                      'Übersicht Personalkosten' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item['staffCosts']['sums']['IT']">
                    {{ item["staffCosts"]["sums"]["IT"] | formatCurrencyNumber }}
                    <feather-icon icon="InfoIcon" :id="'popover-it' + index" />
                    <b-popover :target="'popover-it' + index" triggers="hover" placement="left">
                      <div v-for="(i, j) in item['staffCosts']['groupedByEmployee']['IT']" :key="j">
                        {{ j + 1 }}. {{ i.employee }} - {{ i.amount | formatCurrencyNumber }}
                      </div>
                    </b-popover>
                  </div>
                  <div v-else>
                    0,00 €
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Management (Personalkosten) <feather-icon icon="InfoIcon" id="popover-management" />
                  <b-popover target="popover-management" triggers="hover" placement="right">
                    <span>
                      This data is taken from Traqq report for current month. For past months data is taken from
                      'Übersicht Personalkosten' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item['staffCosts']['sums']['Management']">
                    {{ item["staffCosts"]["sums"]["Management"] | formatCurrencyNumber }}
                    <feather-icon icon="InfoIcon" :id="'popover-management' + index" />
                    <b-popover :target="'popover-management' + index" triggers="hover" placement="left">
                      <div v-for="(i, j) in item['staffCosts']['groupedByEmployee']['Management']" :key="j">
                        {{ j + 1 }}. {{ i.employee }} - {{ i.amount | formatCurrencyNumber }}
                      </div>
                    </b-popover>
                  </div>
                  <div v-else>
                    0,00 €
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Marketing (Werbe/Reisekosten) <feather-icon icon="InfoIcon"
                      id="popover-marketing-werbe-reisekosten" /></b>
                  <b-popover target="popover-marketing-werbe-reisekosten" triggers="hover" placement="right">
                    <span>
                      <b>Marketing Budget + Verwaltungskosten</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ displayTotalMarketingExpenses(displayAdsExpenses(item.dateRange,
                    adsExpensesTable), displayAvgExpenses(item.dateRange, 'Marketing')) | formatCurrencyNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Marketing-Budget <feather-icon icon="InfoIcon" id="popover-marketing-budget" />
                  <b-popover target="popover-marketing-budget" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'KKS - Kampagnen Zahlen (ALL Monthly)' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  {{ displayAdsExpenses(item.dateRange, adsExpensesTable) | formatCurrencyNumber }}
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Verwaltungskosten <feather-icon icon="InfoIcon" id="popover-marketing" />
                  <b-popover target="popover-marketing" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'Marketing' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  {{ displayAvgExpenses(item.dateRange, 'Marketing') | formatCurrencyNumber }}
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Verwaltungskosten <feather-icon icon="InfoIcon"
                      id="popover-verwaltungskosten" /></b>
                  <b-popover target="popover-verwaltungskosten" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'Verwaltungskosten' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ displayAvgExpenses(item.dateRange, 'Verwaltungskosten') |
                    formatCurrencyNumber
                    }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">Fremdleistungen <feather-icon icon="InfoIcon" /></td>
                <td v-for="(item, index) in results" :key="index">
                  0,00 €
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">Nach Partnern aufgeschlüsselt <feather-icon icon="InfoIcon" /></td>
                <td v-for="(item, index) in results" :key="index">
                  0,00 €
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Kosten Warenabgabe <feather-icon icon="InfoIcon"
                      id="popover-kosten-warenabgabe" /></b>
                  <b-popover target="popover-kosten-warenabgabe" triggers="hover" placement="right">
                    <span>
                      <b>Onlineshop (nV) + Amazon (Warenabgabe Amazon) + Ebay (Warenabgabe Ebay) + sonstige (nV)</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ displayDeliveryGoodsCost([displayAvgExpenses(item.dateRange, 'Amazon'),
                  displayAvgExpenses(item.dateRange, 'Ebay')]) | formatCurrencyNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">Onlineshop (nV) <feather-icon icon="InfoIcon" /></td>
                <td v-for="(item, index) in results" :key="index">
                  0,00 €
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Amazon (Warenabgabe Amazon) <feather-icon icon="InfoIcon" id="popover-amazon" />
                  <b-popover target="popover-amazon" triggers="hover" placement="right">
                    <span>
                      This data is taken from pf_amazon_settlementpos table and GMI
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  {{ item['amazonTotalAmount'] | formatCurrencyNumber }}
                  <feather-icon icon="InfoIcon" :id="'popover-amazon' + index" />
                  <b-popover :target="'popover-amazon' + index" triggers="hover" placement="left">
                    <div>1. Fba - {{ item['amazonAmountFba'] | formatCurrencyNumber }}</div>
                    <div>2. Commission - {{ item['amazonAmountCommission'] | formatCurrencyNumber }}</div>
                    <div>3. Ads - {{ item['amazonAmountAds'] | formatCurrencyNumber }}</div>
                  </b-popover>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  Ebay (Warenabgabe Ebay) <feather-icon icon="InfoIcon" id="popover-ebay" />
                  <b-popover target="popover-ebay" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'Ebay' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  {{ displayAvgExpenses(item.dateRange, 'Ebay') | formatCurrencyNumber }}
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">sonstige (nV) <feather-icon icon="InfoIcon" /></td>
                <td v-for="(item, index) in results" :key="index">
                  0,00 €
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Ausgangsfrachten <feather-icon icon="InfoIcon"
                      id="popover-ausgangsfrachten" /></b>
                  <b-popover target="popover-ausgangsfrachten" triggers="hover" placement="right">
                    <span>
                      This data is taken from parsing of PDF invoices (IMAP server and GetMyInvoice service)
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  {{ displayExpensesTransport(item['freightChargesOut']) | formatCurrencyNumber }}
                  <feather-icon icon="InfoIcon" @click="openFreightChargesOutDetails(item.dateRange)" />
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Verpackungsmaterial <feather-icon icon="InfoIcon"
                      id="popover-verpackungsmaterial" /></b>
                  <b-popover target="popover-verpackungsmaterial" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'Verpackungsmaterial' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ displayAvgExpenses(item.dateRange, 'Verpackungsmaterial') |
                    formatCurrencyNumber
                    }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Raumkosten <feather-icon icon="InfoIcon" id="popover-raumkosten" /></b>
                  <b-popover target="popover-raumkosten" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'Raumkosten' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ displayAvgExpenses(item.dateRange, 'Raumkosten') | formatCurrencyNumber
                    }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Versicherungen / Beiträge <feather-icon icon="InfoIcon"
                      id="popover-versicherungen-beitrage" /></b>
                  <b-popover target="popover-versicherungen-beitrage" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'Versicherungen / Beiträge' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ displayAvgExpenses(item.dateRange, 'Versicherungen / Beiträge') |
                    formatCurrencyNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">sonstige betriebliche Abgaben (sonstige Kosten) <feather-icon icon="InfoIcon"
                      id="popover-sonstige-betriebliche-abgaben" /></b>
                  <b-popover target="popover-sonstige-betriebliche-abgaben" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'sonstige betriebliche Abgaben' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ displayAvgExpenses(item.dateRange, 'sonstige betriebliche Abgaben') |
                    formatCurrencyNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">betriebliche Steuern <feather-icon icon="InfoIcon"
                      id="popover-betriebliche-steuern" /></b>
                  <b-popover target="popover-betriebliche-steuern" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'betriebliche Steuern' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ displayAvgExpenses(item.dateRange, 'betriebliche Steuern') |
                    formatCurrencyNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">IT Kosten <feather-icon icon="InfoIcon" id="popover-it-kosten" /></b>
                  <b-popover target="popover-it-kosten" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'IT Kosten' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ displayAvgExpenses(item.dateRange, 'IT Kosten') | formatCurrencyNumber
                    }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Gebühren PayPal <feather-icon icon="InfoIcon"
                      id="popover-gebuhren-paypal" /></b>
                  <b-popover target="popover-gebuhren-paypal" triggers="hover" placement="right">
                    <span>
                      If Zahlungsart from tRechnung table has 'PayPal' then:<br>
                      WHEN sales price bigger then 0 AND sales price lower OR equal 2000 THEN
                      <p><b>PayPal commission = sales price * 0.05229 + 0.35</b></p>
                      WHEN sales price bigger OR equal 2001 AND sales price lower OR equal 5000 THEN
                      <p><b>PayPal commission = sales price * 0.04599 + 0.35</b></p>
                      WHEN sales price bigger OR equal 5001 AND sales price lower OR equal 25000 THEN
                      <p><b>PayPal commission = sales price * 0.04179 + 0.35</b></p>
                      WHEN sales price bigger OR equal 25001 THEN
                      <p><b>PayPal commission = sales price * 0.03759 + 0.35</b></p>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item['turnover'][0].paypal_commission | formatCurrencyNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Gesamtkosten <feather-icon icon="InfoIcon" id="popover-gesamtkosten" /></b>
                  <b-popover target="popover-gesamtkosten" triggers="hover" placement="right">
                    <span>
                      <b>Personalkosten + Marketing (Werbe/Reisekosten) + Ausgangsfrachten + Verwaltungskosten + Kosten
                        Warenabgabe +
                        Verpackungsmaterial + Raumkosten + Versicherungen / Beiträge + sonstige betriebliche Abgaben
                        (sonstige Kosten) + betriebliche Steuern + IT Kosten + Gebühren PayPal</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b>
                    {{ displayTotalCosts([displayStaffCostTotal(item['staffCosts']['sums']),
                    displayTotalMarketingExpenses(displayAdsExpenses(item.dateRange, adsExpensesTable),
                      displayAvgExpenses(item.dateRange, 'Marketing')),
                    displayExpensesTransport(item['freightChargesOut']),
                    displayAvgExpenses(item.dateRange, 'Verwaltungskosten'),
                    displayDeliveryGoodsCost([item['amazonTotalAmount'],
                    displayAvgExpenses(item.dateRange,
                      'Ebay')]),
                    displayAvgExpenses(item.dateRange, 'Verpackungsmaterial'),
                    displayAvgExpenses(item.dateRange, 'Raumkosten'),
                    displayAvgExpenses(item.dateRange, 'Versicherungen / Beiträge'),
                    displayAvgExpenses(item.dateRange, 'sonstige betriebliche Abgaben'),
                    displayAvgExpenses(item.dateRange, 'betriebliche Steuern'),
                    displayAvgExpenses(item.dateRange, 'IT Kosten'),
                    item['turnover'][0].paypal_commission,
                    ]) | formatCurrencyNumber }}
                  </b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-success">Profit <feather-icon icon="InfoIcon" id="popover-profit" /></b>
                  <b-popover target="popover-profit" triggers="hover" placement="right">
                    <span>
                      <b>Bruttomarge (Rohertrag) - Gesamtkosten</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-success">
                    {{ displayProfit(displayGrossMargin(item['turnover'][0].turnover, item['expenses'][0].expenses,
                      item['expenses'][0].open_suporders_amount,
                      item['stockValueDiff']), [
                      displayStaffCostTotal(item['staffCosts']['sums']),
                      displayTotalMarketingExpenses(displayAdsExpenses(item.dateRange, adsExpensesTable),
                        displayAvgExpenses(item.dateRange, 'Marketing')),
                      displayExpensesTransport(item['freightChargesOut']),
                      displayAvgExpenses(item.dateRange, 'Verwaltungskosten'),
                      displayDeliveryGoodsCost([item['amazonTotalAmount'],
                      displayAvgExpenses(item.dateRange,
                        'Ebay')]),
                      displayAvgExpenses(item.dateRange, 'Verpackungsmaterial'),
                      displayAvgExpenses(item.dateRange, 'Raumkosten'),
                      displayAvgExpenses(item.dateRange, 'Versicherungen / Beiträge'),
                      displayAvgExpenses(item.dateRange, 'sonstige betriebliche Abgaben'),
                      displayAvgExpenses(item.dateRange, 'betriebliche Steuern'),
                      displayAvgExpenses(item.dateRange, 'IT Kosten'),
                      item['turnover'][0].paypal_commission,
                    ]) | formatCurrencyNumber }}
                  </b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b class="text-primary">Profit Margin % <feather-icon icon="InfoIcon"
                      id="popover-profit-margin" /></b>
                  <b-popover target="popover-profit-margin" triggers="hover" placement="right">
                    <span>
                      <b>Profit / Umsatzerlöse * 100</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b>
                    {{ displayProfit(displayGrossMargin(item['turnover'][0].turnover, item['expenses'][0].expenses,
                      item['expenses'][0].open_suporders_amount,
                      item['stockValueDiff']), [
                      displayStaffCostTotal(item['staffCosts']['sums']),
                      displayTotalMarketingExpenses(displayAdsExpenses(item.dateRange, adsExpensesTable),
                        displayAvgExpenses(item.dateRange, 'Marketing')),
                      displayExpensesTransport(item['freightChargesOut']),
                      displayAvgExpenses(item.dateRange, 'Verwaltungskosten'),
                      displayDeliveryGoodsCost([item['amazonTotalAmount'],
                      displayAvgExpenses(item.dateRange,
                        'Ebay')]),
                      displayAvgExpenses(item.dateRange, 'Verpackungsmaterial'),
                      displayAvgExpenses(item.dateRange, 'Raumkosten'),
                      displayAvgExpenses(item.dateRange, 'Versicherungen / Beiträge'),
                      displayAvgExpenses(item.dateRange, 'sonstige betriebliche Abgaben'),
                      displayAvgExpenses(item.dateRange, 'betriebliche Steuern'),
                      displayAvgExpenses(item.dateRange, 'IT Kosten'),
                      item['turnover'][0].paypal_commission,
                    ]) / item['turnover'][0].turnover * 100 | formatNumber }} %
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br>
        <b-row>
          <b-col md="6">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary"
              @click="openAvgExpensesDetails">
              Average Expenses
            </b-button>
          </b-col>
          <b-col md="6">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" @click="openAdvancedCompare">
              Advanced Comparison
            </b-button>
          </b-col>
        </b-row>
        <br>
      </b-card>
    </b-overlay>
    <b-modal ref="turnover-goods-modal" size="xl" title="Turnover By Platform" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-overlay :show="showTurnoverGoodsDetails" spinner-variant="primary" variant="transparent" blur="3px"
        rounded="sm">
        <b-table striped hover responsive class="position-relative" :current-page="turnoverGoodsTable.currentPage"
          :items="turnoverGoodsTable.items" :fields="turnoverGoodsTable.fields"
          :sort-by.sync="turnoverGoodsTable.sortBy" :sort-desc.sync="turnoverGoodsTable.sortDesc"
          :sort-direction="turnoverGoodsTable.sortDirection" :filter="turnoverGoodsTable.filter"
          :filter-included-fields="turnoverGoodsTable.filterOn" />

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="turnoverGoodsTable.currentPage" :total-rows="turnoverGoodsTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-overlay>
    </b-modal>
    <b-modal ref="freight-charges-in-modal" size="xl" title="Freight Charges By Shipping Method" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-overlay :show="showFreightChargesInDetails" spinner-variant="primary" variant="transparent" blur="3px"
        rounded="sm">
        <b-table striped hover responsive class="position-relative"
          :current-page="freightChargesMethodTable.currentPage" :items="freightChargesMethodTable.items"
          :fields="freightChargesMethodTable.fields" :sort-by.sync="freightChargesMethodTable.sortBy"
          :sort-desc.sync="freightChargesMethodTable.sortDesc" :sort-direction="freightChargesMethodTable.sortDirection"
          :filter="freightChargesMethodTable.filter" :filter-included-fields="freightChargesMethodTable.filterOn" />

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="freightChargesMethodTable.currentPage"
              :total-rows="freightChargesMethodTable.totalRows" first-number last-number prev-class="prev-item"
              next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-overlay>
    </b-modal>
    <b-modal ref="freight-charges-out-modal" size="xl" title="Freight Charges By Providers" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-overlay spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-table striped hover responsive class="position-relative" :current-page="freightChargesOutTable.currentPage"
          :items="freightChargesOutTable.items" :fields="freightChargesOutTable.fields"
          :sort-by.sync="freightChargesOutTable.sortBy" :sort-desc.sync="freightChargesOutTable.sortDesc"
          :sort-direction="freightChargesOutTable.sortDirection" :filter="freightChargesOutTable.filter"
          :filter-included-fields="freightChargesOutTable.filterOn" />

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="freightChargesOutTable.currentPage" :total-rows="freightChargesOutTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-overlay>
    </b-modal>
    <b-modal ref="avg-expenses-modal" size="xl" title="Avg. Expenses" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-table striped hover responsive class="position-relative" :current-page="avgExpensesTable.currentPage"
        :per-page="20" :items="avgExpensesTable.items" :fields="avgExpensesTable.fields"
        :sort-by.sync="avgExpensesTable.sortBy" :sort-desc.sync="avgExpensesTable.sortDesc"
        :sort-direction="avgExpensesTable.sortDirection" :filter="avgExpensesTable.filter"
        :filter-included-fields="avgExpensesTable.filterOn" @row-clicked="avgExpensesRowClicked">
        <template #cell(delete)="data">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
            @click="openDeleteAvgExpensesConfirm(data.item.id)">
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- pagination -->
        <div>
          <b-pagination v-model="avgExpensesTable.currentPage" :total-rows="avgExpensesTable.totalRows" first-number
            last-number prev-class="prev-item" next-class="next-item" class="mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-row>
        <b-col xl="6" md="6">
        </b-col>
        <b-col xl="6" md="6">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
            @click="addAvgExpenses">
            Add Avg. Expenses
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="advanced-compare-modal" size="xl" title="Advanced compare" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-tabs>
        <b-tab title="Compare turnover data">
          <b-overlay :show="showCompareTurnoverResults" spinner-variant="primary" variant="transparent" blur="3px"
            rounded="sm">
            <b-row>
              <b-col xl="6">
                <b-card title="Step 1. Upload file">
                  <b-form-file v-model="compareTurnoverUploadedFile" placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..." />
                </b-card>
              </b-col>
              <b-col xl="6">
                <b-card title="Step 2. Select date range">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr v-model="compareTurnoverDateRange" placeholder="Date Range" class="form-control"
                      :config="{ mode: 'range' }" />
                  </b-input-group>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="6">
                <b-card title="Step 3. Compare with JTL">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                    @click="runCompareTurnoverData">
                    Compare
                  </b-button>
                </b-card>
              </b-col>
              <b-col xl="6">
                <b-card title="Results">
                  <a :href="linkToTurnoverCompareTable" target="_blank">{{ linkToTurnoverCompareTable }}</a>
                </b-card>
              </b-col>
            </b-row>
          </b-overlay>
        </b-tab>
        <b-tab title="Compare expenses data">
          <b-overlay :show="showCompareExpensesResults" spinner-variant="primary" variant="transparent" blur="3px"
            rounded="sm">
            <b-row>
              <b-col xl="4">
                <b-card title="Step 1. Upload file">
                  <b-form-file v-model="compareExpensesUploadedFile" placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..." />
                </b-card>
              </b-col>
              <b-col xl="4">
                <b-card title="Step 2. Select date range">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr v-model="compareExpensesDateRange" placeholder="Date Range" class="form-control"
                      :config="{ mode: 'range' }" />
                  </b-input-group>
                </b-card>
              </b-col>
              <b-col xl="4">
                <b-card title="Step 3. Select expenses type">
                  <v-select v-model="selectedExpensesType" label="title" placeholder="Expenses Type"
                    :options="allExpensesTypes" />
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="12">
                <b-card title="Step 4. Compare with JTL">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                    @click="runCompareExpensesData">
                    Compare
                  </b-button>
                </b-card>
              </b-col>
            </b-row>
            <b-card title="Link to Google table">
              <a :href="linkToExpensesCompareTable" target="_blank">{{ linkToExpensesCompareTable }}</a>
            </b-card>
          </b-overlay>
        </b-tab>
      </b-tabs>
      <b-card title="Previously created files">
        <b-badge v-for="(item, index) in googleDriveFiles" :key="index" class="google-drive-files" variant="primary">
          <a :href="item.link" target="_blank">{{ item.name }}</a>
        </b-badge>
      </b-card>
    </b-modal>
    <b-modal ref="avg-expenses-details-modal" cancel-variant="outline-secondary" ok-title="Submit" cancel-title="Close"
      centered :title="avgExpensesID ? 'Update' : 'Add'" :no-close-on-backdrop="true" @ok="handleAvgExpensesSubmit"
      @hidden="resetAvgExpensesModal">
      <b-form @submit.prevent>
        <b-form-group label="Name" label-for="avgExpensesName">
          <b-form-input id="avgExpensesName" v-model="avgExpensesName" />
        </b-form-group>
        <b-form-group label="Value" label-for="avgExpensesValue">
          <b-form-input id="avgExpensesValue" v-model="avgExpensesValue" />
        </b-form-group>
        <b-form-group label="Start date" label-for="avgExpensesStartDate">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr id="avgExpensesStartDate" v-model="avgExpensesStartDate" class="form-control" />
          </b-input-group>
        </b-form-group>
        <b-form-group label="End date" label-for="avgExpensesEndDate">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr id="avgExpensesEndDate" v-model="avgExpensesEndDate" class="form-control" />
          </b-input-group>
        </b-form-group>
      </b-form>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BCardHeader,
  BCardText,
  BButtonGroup,
  BOverlay,
  BTable,
  BPagination,
  BButton,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BPopover,
  BTab,
  BTabs,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';
import * as XLSX from 'xlsx';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const EUCountryCodes = [
  'BE', 'BG', 'CZ', 'DK', 'EE', 'IE', 'EL', 'ES', 'FR', 'HR',
  'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL', 'AT', 'PL',
  'PT', 'RO', 'SI', 'SK', 'FI', 'SE',
];

const currentDate = new Date();
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

function getPreviousMonthRange(currentDate) {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Determine the previous month and its year
  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1; // Adjust month index for array (0-indexed)
  const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear;

  // Calculate the last day of the previous month
  const lastDay = new Date(currentYear, currentMonth, 0).getDate(); // Day 0 of the current month gives the last day of the previous month

  // Format the previous month's date range
  const dateRange = `${previousYear}-${formatMonth(new Date(previousYear, previousMonth, 1))}-01 to ${previousYear}-${formatMonth(new Date(previousYear, previousMonth, 1))}-${formatDate(new Date(previousYear, previousMonth, lastDay))}`;
  return dateRange;
};
const previousMonthDateRange = getPreviousMonthRange(currentDate);

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BCardText,
    BButtonGroup,
    BOverlay,
    BTable,
    BPagination,
    BButton,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BPopover,
    BTab,
    BTabs,
    vSelect,
    flatPickr,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: true,
      showFreightChargesInDetails: true,
      showExportFullTableStart: false,
      showCompareTurnoverResults: false,
      showCompareExpensesResults: false,
      showTurnoverGoodsDetails: true,
      expandedItems: {},
      dateRangesM: [
        {
          oldDateRange: previousMonthDateRange,
          dateRange: previousMonthDateRange
        },
        {
          oldDateRange: `${currentDate.getFullYear()}-${formatMonth(currentDate)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
          dateRange: `${currentDate.getFullYear()}-${formatMonth(currentDate)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`
        },
      ],
      dateRangesY: [
        {
          oldDateRange: `${currentDate.getFullYear() - 1}-01-01 to ${currentDate.getFullYear() - 1}-12-31`,
          dateRange: `${currentDate.getFullYear() - 1}-01-01 to ${currentDate.getFullYear() - 1}-12-31`
        },
        {
          oldDateRange: `${currentDate.getFullYear() - 1}-01-01 to ${currentDate.getFullYear() - 1}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
          dateRange: `${currentDate.getFullYear() - 1}-01-01 to ${currentDate.getFullYear() - 1}-${formatMonth(currentDate)}-${formatDate(currentDate)}`
        },
        {
          oldDateRange: `${currentDate.getFullYear()}-01-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
          dateRange: `${currentDate.getFullYear()}-01-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`
        },
      ],
      compareTurnoverUploadedFile: [],
      compareExpensesUploadedFile: [],
      results: [],
      adsExpensesTable: [],
      allAvgExpenses: [],
      avgExpensesID: '',
      avgExpensesName: '',
      avgExpensesValue: '',
      avgExpensesStartDate: '',
      avgExpensesEndDate: '',
      compareTurnoverDateRange: '',
      compareExpensesDateRange: '',
      selectedExpensesType: '',
      allExpensesTypes: ['Waren Frachtkosten', 'Ausgangsfrachten'],
      linkToTurnoverCompareTable: '',
      linkToExpensesCompareTable: '',
      googleDriveFiles: [],
      turnoverGoodsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'platform', label: 'platform', sortable: true },
          {
            key: 'turnover',
            label: 'amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      freightChargesMethodTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'freight_charges',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'method', label: 'method', sortable: true },
          {
            key: 'freight_charges',
            label: 'freight charges',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      freightChargesOutTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'total_amount',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'provider', label: 'provider' },
          {
            key: 'amount_curr_year',
            label: 'amount selected year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'amount_prev_year',
            label: 'amount prev. year same period',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'absolute_change',
            label: 'change',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'percentage_change',
            label: 'percentage change',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'amount_prev_full_year',
            label: 'amount prev. full year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      avgExpensesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'name',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'value',
            label: 'value',
            sortable: true,
            formatter: val => `${this.$formatCurrencyNumber(parseFloat(val))}`,
          },
          {
            key: 'start_date',
            label: 'start date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'end_date',
            label: 'end date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'delete', label: '' },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    this.show = true;

    // Grouped by months
    for (let i = 0; i < this.dateRangesM.length; i++) {
      const dateRange = this.dateRangesM[i].dateRange.split(' to ');
      let queryParams = {
        from_date: dateRange[0],
        to_date: dateRange[1]
      };
      await this.getDataByMonth(queryParams);
    }

    // Get Avg Expenses
    await this.getAvgExpenses();
    // Get Ads Expenses
    await this.getAdsExpenses();

    // Grouped by years
    for (let i = 0; i < this.dateRangesY.length; i++) {
      const dateRange = this.dateRangesY[i].dateRange.split(' to ');
      let queryParams = {
        from_date: dateRange[0],
        to_date: dateRange[1]
      };
      await this.getDataFullYear(queryParams);
    }

    this.show = false;
  },
  filters: {
    formatDateRange(value) {
      const parts = value.split(' to ');
      if (parts.length !== 2) {
        return value;  // Return the original string if it doesn't match expected format
      }
      const [startDate, endDate] = parts;
      const formattedStartDate = startDate.split('-').reverse().join('.');
      const formattedEndDate = endDate.split('-').reverse().join('.');
      return `${formattedStartDate} to ${formattedEndDate}`;
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    includesToday(item) {
      const today = new Date();
      const currentYear = today.getFullYear();
      const monthAsString = String(item.month); // Ensure item.month is treated as a string

      // Check if the month is strictly a year (4 digits) and is less than the current year
      if (monthAsString.match(/^\d{4}$/) && parseInt(monthAsString, 10) < currentYear) {
        const [startDateStr, endDateStr] = item.dateRange.split(' to ');

        const endDate = new Date(endDateStr.trim());
        const endMonth = endDate.getMonth(); // Month (0-11)
        const endDay = endDate.getDate(); // Day (1-31)

        // Compare endDate's month and day with today's month and day
        return today.getDate() === endDay && today.getMonth() === endMonth;
      }
      return false; // If the conditions are not met, return false
    },
    isCurrentMonthAndYear(dateStr) {
      const targetDate = new Date(dateStr);

      return targetDate.getMonth() === currentDate.getMonth() &&
        targetDate.getFullYear() === currentDate.getFullYear();
    },
    formatDateToLocal(date) {
      const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
      const localISOTime = (new Date(date - tzOffset)).toISOString().slice(0, 10);
      return localISOTime;
    },
    getMonthlyDateRanges(startDate, endDate) {
      const ranges = [];
      let current = new Date(startDate);
      const end = new Date(endDate);

      while (current <= end) {
        // Start of the month for 'current' or the actual start date if it's the first loop iteration
        const adjustedStart = new Date(current.getFullYear(), current.getMonth(), current.getDate() === 1 ? 1 : current.getDate());

        // Calculate the last day of the current month
        const endOfMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0);

        // End of the month or the actual end date if it falls within this month
        const adjustedEnd = endOfMonth > end ? end : endOfMonth;

        // Add the current month's range to the array
        ranges.push({
          from_date: this.formatDateToLocal(adjustedStart),
          to_date: this.formatDateToLocal(adjustedEnd)
        });

        // Move 'current' to the first day of the next month
        current = new Date(adjustedStart.getFullYear(), adjustedStart.getMonth() + 1, 1);
      }

      return ranges;
    },
    adjustFromDateRange(dateRange) {
      const fromDate = new Date(dateRange.from_date);
      const toDate = new Date(dateRange.to_date);

      // Subtract 1 day from the from_date
      const previousDayFromDate = new Date(fromDate);
      previousDayFromDate.setDate(fromDate.getDate() - 1);

      // Calculate the last day of the month for the to_date
      const lastDayOfMonth = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0);

      // Format the date as YYYY-MM-DD without relying on ISO string
      const formattedFromDate = `${previousDayFromDate.getFullYear()}-${(previousDayFromDate.getMonth() + 1).toString().padStart(2, '0')}-${previousDayFromDate.getDate().toString().padStart(2, '0')}`;
      const formattedToDate = `${lastDayOfMonth.getFullYear()}-${(lastDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfMonth.getDate().toString().padStart(2, '0')}`;

      return {
        from_date: formattedFromDate,
        to_date: formattedToDate
      };
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getDataByMonth(queryParams) {
      try {
        const fromIsCurrent = this.isCurrentMonthAndYear(queryParams['from_date']);
        const toIsCurrent = this.isCurrentMonthAndYear(queryParams['to_date']);
        const staffCostsEndpoint = (fromIsCurrent && toIsCurrent)
          ? `${process.env.VUE_APP_ROOT_API}/staff-costs/`
          : `${process.env.VUE_APP_ROOT_API}/staff-cost-corrections/`;

        const stockValueDateRange = this.adjustFromDateRange(queryParams);
        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/finance-report-turnover/`, queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/finance-report-expenses/`, queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/freight-charges-out-jtl/`, queryParams),
          this.makeRequest(staffCostsEndpoint, queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/finance-report-turnover-by-tax-type/`, queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/stock-value-history/`, { date: queryParams["from_date"] }),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/stock-value-history/`, { date: queryParams["to_date"] }),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/stock-value-purchasing-amounts/`, { date: stockValueDateRange["from_date"] }),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/stock-value-purchasing-amounts/`, { date: stockValueDateRange["to_date"] }),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/amazon-fba-commission-by-month/`, queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/amazon-ads-by-month/`, queryParams),
        ]);

        const responseResults = {};
        const year = response[0].data.results[0].year;
        const monthIndex = response[0].data.results[0].month - 1;

        // Stock value
        // const stockValueBegin = response[5].data.results[0]["pprice"];
        // const stockValueEnd = response[6].data.results[0]["pprice"];

        let stockValueBegin = response[7].data;
        let stockValueEnd = response[8].data;

        if (stockValueBegin.length == 1) {
          stockValueBegin = stockValueBegin[0]["amount"];
        }
        else {
          stockValueBegin = 0.0;
        }

        if (stockValueEnd.length == 1) {
          stockValueEnd = stockValueEnd[0]["amount"];
        }
        else {
          stockValueEnd = 0.0;
        }

        responseResults.dateRange = `${queryParams["from_date"]} to ${queryParams["to_date"]}`;
        responseResults.month = `${(new Date(year, monthIndex, 1)).toLocaleString('en-us', { month: 'short' })}-${year}`;
        responseResults.turnover = response[0].data.results;
        responseResults.turnoverByTaxType = response[4].data.results;
        responseResults.expenses = response[1].data.results;
        responseResults.freightChargesOut = response[2].data.results;
        if (stockValueEnd == 0) {
          responseResults.stockValueDiff = 0.0;
        }
        else {
          responseResults.stockValueDiff = stockValueBegin - stockValueEnd;
        }
        responseResults.staffCosts = this.calculateStaffCosts(response[3].data);

        // Amazon
        responseResults.amazonAmountFba = response[9].data.results?.[0]?.total_amount_fba || 0;
        responseResults.amazonAmountCommission = response[9].data.results?.[0]?.total_amount_commission || 0;
        responseResults.amazonAmountAds = parseFloat(response[10].data?.[0]?.total_amount || 0);
        responseResults.amazonTotalAmount = (responseResults.amazonAmountFba + responseResults.amazonAmountCommission +
          responseResults.amazonAmountAds);

        this.results.push(responseResults);

        // Sort by date
        this.results.sort((a, b) => {
          const keyA = new Date(a.month);
          const keyB = new Date(b.month);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getAdsExpenses() {
      await axios
        .get(`${process.env.VUE_APP_ROOT_API}/ads-expenses/`, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          this.adsExpensesTable = response.data.results;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.push({ name: 'login' });
          }
        })
    },
    async getDataFullYear(queryParams) {
      try {
        const stockValueDateRange = this.adjustFromDateRange(queryParams);
        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/finance-report-turnover-full-year/`, queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/finance-report-expenses-full-year/`, queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/freight-charges-out-jtl-by-year/`, queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/staff-cost-corrections/`, queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/stock-value-history/`, { date: queryParams["from_date"] }),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/stock-value-history/`, { date: queryParams["to_date"] }),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/stock-value-purchasing-amounts/`, { date: stockValueDateRange["from_date"] }),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/stock-value-purchasing-amounts/`, { date: stockValueDateRange["to_date"] }),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/amazon-fba-commission-by-year/`, queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/amazon-ads-by-year/`, queryParams),
        ]);

        const responseResults = {};

        // Stock value
        // const stockValueBegin = response[4].data.results[0]["pprice"];
        // const stockValueEnd = response[5].data.results[0]["pprice"];

        let stockValueBegin = response[6].data;
        let stockValueEnd = response[7].data;

        if (stockValueBegin.length == 1) {
          stockValueBegin = stockValueBegin[0]["amount"];
        }
        else {
          stockValueBegin = 0.0;
        }

        if (stockValueEnd.length == 1) {
          stockValueEnd = stockValueEnd[0]["amount"];
        }
        else {
          stockValueEnd = 0.0;
        }

        responseResults.dateRange = `${queryParams["from_date"]} to ${queryParams["to_date"]}`;
        responseResults.month = response[0].data.results[0].year;
        responseResults.turnover = response[0].data.results;
        responseResults.expenses = response[1].data.results;
        responseResults.freightChargesOut = response[2].data.results;
        if (stockValueEnd == 0) {
          responseResults.stockValueDiff = 0.0;
        }
        else {
          responseResults.stockValueDiff = stockValueBegin - stockValueEnd;
        }
        responseResults.staffCosts = this.calculateStaffCosts(response[3].data);

        // Amazon
        responseResults.amazonAmountFba = response[8].data.results?.[0]?.total_amount_fba || 0;
        responseResults.amazonAmountCommission = response[8].data.results?.[0]?.total_amount_commission || 0;
        responseResults.amazonAmountAds = parseFloat(response[9].data?.[0]?.total_amount || 0);
        responseResults.amazonTotalAmount = (responseResults.amazonAmountFba + responseResults.amazonAmountCommission +
          responseResults.amazonAmountAds);

        this.results.push(responseResults);

        // Sort by date
        this.results.sort((a, b) => {
          const keyA = new Date(a.month);
          const keyB = new Date(b.month);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getAvgExpenses() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/avg-expenses/`, {});
        const data = response.data.results;
        this.avgExpensesTable.items = data;
        this.avgExpensesTable.totalRows = data.length;
        this.allAvgExpenses = data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    displayTotalMarketingExpenses(adsExpenses, marketingExpenses) {
      return parseFloat(adsExpenses) + parseFloat(marketingExpenses);
    },
    displayAdsExpenses(dateRange, adsExpensesTable) {
      // Split the dateRange into start and end dates
      const [startDate, endDate] = dateRange.split(' to ');

      // Parse dates for comparison
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Filter adsExpensesTable for entries within the date range and sum their costs
      const sumCosts = adsExpensesTable.reduce((sum, entry) => {
        const entryDate = new Date(entry.month_year);
        if (entryDate >= start && entryDate <= end) {
          return sum + entry.cost;
        }
        return sum;
      }, 0.0);

      return sumCosts;
    },
    displayAvgExpenses(dateRange, name) {
      let res = 0.0;
      // Split dateRange into start and end
      const [startDateRange, endDateRange] = dateRange.split(' to ').map(date => new Date(date));
      const items = this.allAvgExpenses.filter(x => x.name === name);

      items.forEach(item => {
        let value = parseFloat(item.value);
        let startDate = new Date(item.start_date);
        let endDate = new Date(item.end_date);

        // Determine overlap of the item date range with the specified date range
        let startMax = new Date(Math.max(startDate, startDateRange));
        let endMin = new Date(Math.min(endDate, endDateRange));

        if (startMax <= endMin) {
          let monthsCovered = this.getMonthsCovered(startMax, endMin);
          res += value * monthsCovered;
        }
      });

      return res;
    },
    getMonthsCovered(start, end) {
      let months = (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth() + 1;
      return Math.max(0, months);
    },
    displayExpensesTransport(value) {
      if (value.length > 0) {
        return value.map(item => parseFloat(item.total_amount)).reduce((a, b) => (a + b));
      }
      return 0.0;
    },
    displayTotalExpenses(expenses, unbookedSuporders, stockValue) {
      return (expenses + unbookedSuporders + stockValue);
    },
    displayGrossMargin(turnover, expenses, unbookedSuporders, stockValue) {
      return (turnover - expenses - unbookedSuporders - stockValue);
    },
    displayGrossMarginP(grossMargin, turnover) {
      const val = (grossMargin / turnover * 100).toFixed(2);
      return parseFloat(val);
    },
    displayStaffCostTotal(staffCosts) {
      const values = Object.values(staffCosts);
      return values.reduce((accumulator, value) => accumulator + value, 0);
    },
    displayDeliveryGoodsCost(costs) {
      const total = costs.reduce((accumulator, currentValue) => {
        const numericValue = parseFloat(currentValue);
        return accumulator + (isNaN(numericValue) ? 0 : numericValue);
      }, 0);
      return total;
    },
    displayProfit(income, outcome) {
      const totalOutcome = outcome.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue));
      return (parseFloat(income) - totalOutcome);
    },
    displayTotalCosts(outcome) {
      const totalOutcome = outcome.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue));
      return totalOutcome;
    },
    calculateStaffCosts(results) {
      let staffGrouped = {};
      staffGrouped["sums"] = {};
      staffGrouped["groupedByEmployee"] = {};

      results.forEach(item => {
        if (staffGrouped["sums"][item.team_name] == null) {
          staffGrouped["sums"][item.team_name] = 0.0;
        }

        if (item.total_extra_amount) {
          staffGrouped["sums"][item.team_name] += parseFloat(item.total_amount) + parseFloat(item.total_extra_amount);
        }
        else {
          staffGrouped["sums"][item.team_name] += parseFloat(item.total_amount);
        }

        if (staffGrouped["groupedByEmployee"][item.team_name] == null) {
          staffGrouped["groupedByEmployee"][item.team_name] = [];
        }

        if (staffGrouped["groupedByEmployee"][item.team_name].some(e => e.employee == item.full_name)) {
          let index = staffGrouped["groupedByEmployee"][item.team_name].findIndex(x => x.employee === item.full_name);
          if (item.total_extra_amount) {
            staffGrouped["groupedByEmployee"][item.team_name][index].total_amount += parseFloat(item.total_amount) + parseFloat(item.total_extra_amount);
          }
          else {
            staffGrouped["groupedByEmployee"][item.team_name][index].total_amount += parseFloat(item.total_amount);
          }
        }
        else {
          if (item.total_extra_amount) {
            staffGrouped["groupedByEmployee"][item.team_name].push({ employee: item.full_name, amount: parseFloat(item.total_amount) + parseFloat(item.total_extra_amount) });
          }
          else {
            staffGrouped["groupedByEmployee"][item.team_name].push({ employee: item.full_name, amount: parseFloat(item.total_amount) });
          }
        }
      })

      return staffGrouped;
    },
    async getTurnoverGoods(dr) {
      this.showTurnoverGoodsDetails = true;
      try {
        const dateRange = dr.split(' to ');
        let queryParams = {
          from_date: dateRange[0],
          to_date: dateRange[1]
        };
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-by-platform/`, queryParams);
        this.turnoverGoodsTable.items = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showTurnoverGoodsDetails = false;
      }
    },
    async getFreightChargesIn(dr) {
      this.showFreightChargesInDetails = true;

      const dateRange = dr.split(' to ');
      let queryParams = {
        from_date: dateRange[0],
        to_date: dateRange[1]
      };

      const requestFreightChargesIn = await axios.get(`${process.env.VUE_APP_ROOT_API}/freight-charges-method/`, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params: queryParams,
      })
      const requestShippingMethods = await axios.get(`${process.env.VUE_APP_ROOT_API}/all-shipping-methods/`, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
      })

      await axios
        .all([
          requestFreightChargesIn,
          requestShippingMethods,
        ])
        .then(response => {
          const shippingMethods = response[1].data;
          const freightChargesMethodIn = response[0].data.results;

          // Map by name
          freightChargesMethodIn.forEach(i => {
            shippingMethods.forEach(j => {
              if (i.method === j.name) {
                i.method = j.maps_to_name.name;
              }
            })
          })

          // Group by method name
          const resultFreightChargesMethodIn = [];
          freightChargesMethodIn.reduce((res, value) => {
            if (!res[value.method]) {
              res[value.method] = { method: value.method, freight_charges: 0 };
              resultFreightChargesMethodIn.push(res[value.method]);
            }
            res[value.method].freight_charges += value.freight_charges;
            return res;
          }, {});

          this.freightChargesMethodTable.items = resultFreightChargesMethodIn;
          this.showFreightChargesInDetails = false;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.push({ name: 'login' });
          }
        })
    },
    async getFreightChargesOut(dr) {
      this.show = true;
      const dateRange = dr.split(' to ');
      let fromDate = new Date(dateRange[0]);
      let toDate = new Date(dateRange[1]);

      const queryParamsList = [
        [`${fromDate.getFullYear()}-${formatMonth(fromDate)}-${formatDate(fromDate)}`, `${toDate.getFullYear()}-${formatMonth(toDate)}-${formatDate(toDate)}`],
        [`${fromDate.getFullYear() - 1}-${formatMonth(fromDate)}-${formatDate(fromDate)}`, `${toDate.getFullYear() - 1}-${formatMonth(toDate)}-${formatDate(toDate)}`],
        [`${fromDate.getFullYear() - 1}-01-${formatDate(fromDate)}`, `${toDate.getFullYear() - 1}-12-31`]
      ];
      const queryParams = {};
      let currResults = [];
      for (let i = 0; i < queryParamsList.length; i++) {
        queryParams.from_date = queryParamsList[i][0];
        queryParams.to_date = queryParamsList[i][1];
        try {
          const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/freight-charges-out-jtl/`, queryParams);
          currResults.push(response.data.results);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'login' });
          } else {
            // Handle other types of errors
            console.error('An error occurred:', error);
          }
        }
      }

      let combinedArray = [];
      currResults.forEach((subArray, index) => {
        // Aggregate amounts by provider within each sub-array
        const aggregatedByProvider = {};

        subArray.forEach(item => {
          const providers = [
            { field: 'dp_amount', name: 'Deutsche Post' },
            { field: 'schenker_amount', name: 'Schenker' },
            { field: 'ppl_amount', name: 'PPL' }
          ];

          providers.forEach(provider => {
            const amount = parseFloat(item[provider.field] || 0);

            if (amount > 0) {
              if (!aggregatedByProvider[provider.name]) {
                aggregatedByProvider[provider.name] = 0;
              }
              aggregatedByProvider[provider.name] += amount; // Sum amounts across months
            }
          });
        });

        // Process the aggregated data
        Object.keys(aggregatedByProvider).forEach(providerName => {
          const amount = aggregatedByProvider[providerName];

          let existingItem = combinedArray.find(ci => ci.provider === providerName);

          if (!existingItem) {
            existingItem = {
              provider: providerName,
              amount_curr_year: 0,
              amount_prev_year: 0,
              absolute_change: 0,
              percentage_change: 0,
              amount_prev_full_year: 0,
            };
            combinedArray.push(existingItem);
          }

          if (index === 0) {
            existingItem.amount_curr_year = amount;
          } else if (index === 1) {
            existingItem.amount_prev_year = amount;
          } else {
            existingItem.amount_prev_full_year = amount;
          }

          // Calculate the absolute and percentage change
          if (existingItem.amount_curr_year && existingItem.amount_prev_year) {
            let absoluteChange = existingItem.amount_curr_year - existingItem.amount_prev_year;
            existingItem.absolute_change = absoluteChange;
            existingItem.percentage_change = (absoluteChange / existingItem.amount_prev_year) * 100;
          }
        });
      });

      const amountCurrYearSum = combinedArray.map(item => item.amount_curr_year).reduce((a, b) => (a + b));
      const amountPrevYearSum = combinedArray.map(item => item.amount_prev_year).reduce((a, b) => (a + b));
      const absoluteChangerSum = amountCurrYearSum - amountPrevYearSum;
      const percentageChangerSum = (absoluteChangerSum / amountPrevYearSum) * 100;
      const amountPrevFullYearSum = combinedArray.map(item => item.amount_prev_full_year).reduce((a, b) => (a + b));

      combinedArray.push({
        provider: "Total",
        amount_curr_year: amountCurrYearSum,
        amount_prev_year: amountPrevYearSum,
        absolute_change: absoluteChangerSum,
        percentage_change: percentageChangerSum,
        amount_prev_full_year: amountPrevFullYearSum,
      });

      this.freightChargesOutTable.items = combinedArray;

      this.show = false;
    },
    async getAllComparedFiles() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-compared-files/`, {});
        this.googleDriveFiles = response.data.files;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async changeDateRangeY() {
      this.show = true;
      const dateRange = this.dateRangesY[1].dateRange.split(' to ');
      const oldDateRange = this.dateRangesY[1].oldDateRange.split(' to ');
      if (dateRange.length === 2 && dateRange[0] && dateRange[1]) {
        // Check if the new date range is different from the old one
        if (dateRange[0] !== oldDateRange[0] || dateRange[1] !== oldDateRange[1]) {
          let queryParams = {
            from_date: dateRange[0],
            to_date: dateRange[1]
          };
          await this.getDataFullYear(queryParams);
          // Update the oldDateRange to the new dateRange after fetching data
          this.dateRangesY[1].oldDateRange = this.dateRangesY[1].dateRange;
        }
      }
      this.show = false;
    },
    async changeDateRangeM() {
      this.show = true;
      const dateRange = this.dateRangesM[1].dateRange.split(' to ');
      const oldDateRange = this.dateRangesM[1].oldDateRange.split(' to ');
      if (dateRange.length === 2 && dateRange[0] && dateRange[1]) {
        // Check if the new date range is different from the old one
        if (dateRange[0] !== oldDateRange[0] || dateRange[1] !== oldDateRange[1]) {
          const monthlyRanges = this.getMonthlyDateRanges(dateRange[0], dateRange[1]);
          for (const range of monthlyRanges) {
            await this.getDataByMonth(range);
          }
          // Update the oldDateRange to the new dateRange after fetching data
          this.dateRangesM[1].oldDateRange = this.dateRangesM[1].dateRange;
        }
      }
      this.show = false;
    },
    async openTurnoverGoodsDetails(dateRange) {
      await this.getTurnoverGoods(dateRange);
      this.$refs['turnover-goods-modal'].show();
    },
    async openFreightChargesInDetails(dateRange) {
      await this.getFreightChargesIn(dateRange);
      this.$refs['freight-charges-in-modal'].show();
    },
    async openGoodsCostsDetails(dateRange) {
      await this.getGoodsCosts(dateRange);
      this.$refs['goods-costs-modal'].show();
    },
    async openFreightChargesOutDetails(dateRange) {
      await this.getFreightChargesOut(dateRange);
      this.$refs['freight-charges-out-modal'].show();
    },
    openAvgExpensesDetails() {
      this.$refs['avg-expenses-modal'].show();
    },
    async openAdvancedCompare() {
      await this.getAllComparedFiles();
      this.$refs['advanced-compare-modal'].show();
    },
    addAvgExpenses() {
      this.$refs['avg-expenses-details-modal'].show();
    },
    avgExpensesRowClicked(row) {
      this.avgExpensesID = row.id;
      this.avgExpensesName = row.name;
      this.avgExpensesValue = row.value;
      this.avgExpensesStartDate = row.start_date;
      this.avgExpensesEndDate = row.end_date;
      this.$refs['avg-expenses-details-modal'].show();
    },
    resetAvgExpensesModal() {
      this.avgExpensesID = '';
      this.avgExpensesName = '';
      this.avgExpensesValue = '';
      this.avgExpensesStartDate = '';
      this.avgExpensesEndDate = '';
    },
    toggleTurnoverByTaxTypeDetail(index) {
      // Toggle the expanded state for the given index
      this.$set(this.expandedItems, index, !this.expandedItems[index]);
    },
    async handleAvgExpensesSubmit() {
      const params = {
        name: this.avgExpensesName,
        value: this.avgExpensesValue,
        start_date: this.avgExpensesStartDate,
        end_date: this.avgExpensesEndDate,
      };

      if (this.avgExpensesID && this.avgExpensesName !== '' && this.avgExpensesValue !== '' && this.avgExpensesStartDate !== '' && this.avgExpensesEndDate !== '') {
        await axios
          .put(`${process.env.VUE_APP_ROOT_API}/avg-expenses/${this.avgExpensesID}/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Avg. Expenses have been updated');
            await this.getAvgExpenses();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else if (this.avgExpensesName !== '' && this.avgExpensesValue !== '' && this.avgExpensesStartDate !== '' && this.avgExpensesEndDate !== '') {
        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/avg-expenses/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Avg. Expenses have been created');
            await this.getAvgExpenses();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else { this.showToast('danger', 'Error!'); }
    },
    openDeleteAvgExpensesConfirm(id) {
      this.avgExpensesID = id;
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await axios
            .delete(`${process.env.VUE_APP_ROOT_API}/avg-expenses/${this.avgExpensesID}/`, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(async response => {
              this.showToast('danger', 'Avg. Expenses have been deleted');
              await this.getAvgExpenses();
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
        this.avgExpensesID = '';
      })
    },
    async exportGoodsCosts(dr) {
      try {
        const dateRange = dr.split(' to ');
        let queryParams = {
          from_date: dateRange[0],
          to_date: dateRange[1]
        };
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/goods-costs/`, queryParams);

        const exportedData = response.data.results;
        const euExportedData = [];
        const deExportedData = [];
        const otherExportedData = [];

        // Classify the data into different arrays based on the country code
        exportedData.forEach(item => {
          if (EUCountryCodes.includes(item.country_code)) {
            euExportedData.push(item);
          } else if (item.country_code === 'DE') {
            deExportedData.push(item);
          } else {
            otherExportedData.push(item);
          }
        });

        // Create a new workbook
        let workbook = XLSX.utils.book_new();

        // Add EU data sheet if not empty
        if (euExportedData.length > 0) {
          let worksheet = XLSX.utils.json_to_sheet(euExportedData);
          XLSX.utils.book_append_sheet(workbook, worksheet, 'EU Costs');
        }

        // Add Germany data sheet if not empty
        if (deExportedData.length > 0) {
          let worksheet = XLSX.utils.json_to_sheet(deExportedData);
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Germany Costs');
        }

        // Add Other data sheet if not empty
        if (otherExportedData.length > 0) {
          let worksheet = XLSX.utils.json_to_sheet(otherExportedData);
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Other Costs');
        }

        // Export the workbook to an xlsx file only if there are any sheets
        if (workbook.SheetNames.length > 0) {
          XLSX.writeFile(workbook, 'GoodsCostsByRegion.xlsx');
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    exportFullTableToExcel() {
      this.showExportFullTableStart = true;
      let table = this.$refs.fullTable;
      let data = [];

      // Parse table rows
      for (let i = 0; i < table.rows.length; i++) {
        let row = [];
        for (let j = 0; j < table.rows[i].cells.length; j++) {
          row.push(table.rows[i].cells[j].innerText);
        }
        data.push(row);
      }
      this.showExportFullTableStart = false;

      // Convert the data to a worksheet
      let worksheet = XLSX.utils.aoa_to_sheet(data);

      // Create a new workbook, and add the worksheet to it
      let workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

      // Export the workbook to an xlsx file
      XLSX.writeFile(workbook, 'FinanceReport.xlsx');
    },
    async exportTurnoverPositions(dr) {
      try {
        const dateRange = dr.split(' to ');
        let queryParams = {
          from_date: dateRange[0],
          to_date: dateRange[1]
        };
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/invoice-positions/`, queryParams);
        const exportedData = response.data.results;

        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);

        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

        // Write the workbook to a file
        XLSX.writeFile(workbook, 'InvoicePositions.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async exportInvoicesByTaxType(dr) {
      const dateRange = dr.split(' to ');
      let queryParams = {
        from_date: dateRange[0],
        to_date: dateRange[1]
      };

      await axios
        .get(`${process.env.VUE_APP_ROOT_API}/invoices-by-tax-type/`, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
          params: queryParams,
        })
        .then(response => {
          // Create a new workbook
          let wb = XLSX.utils.book_new();

          // Group the data
          let groupedData = response.data.results.reduce((groups, item) => {
            const group = item.tax_type;
            if (!groups[group]) groups[group] = [];
            groups[group].push(item);
            return groups;
          }, {});

          // For each group, create a new worksheet and add it to the workbook
          for (let group in groupedData) {
            let sheetName = group;
            if (sheetName.length > 31) {
              sheetName = sheetName.substring(0, 31);
            }
            let ws = XLSX.utils.json_to_sheet(groupedData[group]);
            XLSX.utils.book_append_sheet(wb, ws, sheetName);
          }

          // Write the workbook to a file
          XLSX.writeFile(wb, 'ByTaxType.xlsx');
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.push({ name: 'login' });
          }
        })
    },
    async exportComparedData(data, type) {
      let timeframe;
      if (type.includes('Umsatz')) {
        timeframe = this.compareTurnoverDateRange;
      } else {
        timeframe = this.compareExpensesDateRange;
      }
      const filename = `${type} ${timeframe}.xlsx`;

      // Convert the data to a worksheet
      let worksheet = XLSX.utils.json_to_sheet(data);

      // Create a new workbook, and add the worksheet to it
      let workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

      // Export the workbook to an xlsx file
      XLSX.writeFile(workbook, filename);
    },
    async exportStaffCosts(dr) {
      try {
        const dateRange = dr.split(' to ');
        let queryParams = {
          from_date: dateRange[0],
          to_date: dateRange[1]
        };
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/staff-cost-corrections/`, queryParams);
        // Create a new workbook
        let wb = XLSX.utils.book_new();

        // Group the data
        let groupedData = response.data.reduce((groups, item) => {
          // Determine the group key based on total_extra_amount
          const groupKey = item.is_freelancer == true ? 'freelancers' : 'regular employees';
          // Initialize the group array if it doesn't exist
          if (!groups[groupKey]) {
            groups[groupKey] = [];
          }
          // Add the item to the appropriate group
          groups[groupKey].push(item);
          return groups;
        }, {});

        // For each group, create a new worksheet and add it to the workbook
        for (let group in groupedData) {
          let sheetName = group;
          if (sheetName.length > 31) {
            sheetName = sheetName.substring(0, 31);
          }
          let ws = XLSX.utils.json_to_sheet(groupedData[group]);
          XLSX.utils.book_append_sheet(wb, ws, sheetName);
        }

        // Write the workbook to a file
        XLSX.writeFile(wb, 'StaffCosts.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async runCompareTurnoverData() {
      if (this.compareTurnoverUploadedFile.length != 0) {
        this.showCompareTurnoverResults = true;
        const formData = new FormData();
        const dateRange = this.compareTurnoverDateRange.split(' to ');
        formData.append('file', this.compareTurnoverUploadedFile);
        formData.append('from_date', dateRange[0]);
        formData.append('to_date', dateRange[1]);

        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/compare-turnover-data/`, formData, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(response => {
            if (response.data.success) {
              this.linkToTurnoverCompareTable = response.data.results.link;
              this.showToast('success', response.data.message);
            } else {
              this.showToast('danger', response.data.message);
            }
            this.showCompareTurnoverResults = false;
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      }
      else {
        this.showToast('warning', 'Please, choose a file');
      }
    },
    async runCompareExpensesData() {
      if (this.compareExpensesUploadedFile.length != 0) {
        this.showCompareExpensesResults = true;
        const formData = new FormData();
        const dateRange = this.compareExpensesDateRange.split(' to ');
        formData.append('file', this.compareExpensesUploadedFile);
        formData.append('from_date', dateRange[0]);
        formData.append('to_date', dateRange[1]);

        if (this.selectedExpensesType == 'Waren Frachtkosten') {
          await axios
            .post(`${process.env.VUE_APP_ROOT_API}/compare-expenses-data/`, formData, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(response => {
              if (response.data.success) {
                this.linkToExpensesCompareTable = response.data.results.link;
                this.showToast('success', response.data.message);
              } else {
                this.showToast('danger', response.data.message);
              }
              this.showCompareExpensesResults = false;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
        else {
          await axios
            .post(`${process.env.VUE_APP_ROOT_API}/compare-expenses-freight-charges-data/`, formData, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(response => {
              console.log(response);
              this.showCompareExpensesResults = false;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
      }
      else {
        this.showToast('warning', 'Please, choose a file');
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.table-wrapper {
  width: 100%;
  overflow: auto;
  max-height: 600px;
}

table {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
}

thead {
  position: sticky;
  top: 0;
}

.sticky-col {
  position: sticky;
  left: 0;
  background-color: #f3f2f7;
}

.google-drive-files {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}
</style>
